/** @jsx jsx */
import { jsx } from 'theme-ui'

export default props =>
  <div
    {...props}
    sx={{
      margin: '0 auto',
      maxWidth: 'container',
      p: [0]
    }}
  />