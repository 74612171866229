/** @jsx jsx */
// import React from "react"
import { Styled, jsx } from "theme-ui";
import ReactMarkdown from "react-markdown";

import { StaticQuery, graphql } from "gatsby";

import Main from "../components/Main";
import Image from "../components/Image";
// import ImageNew from "../components/ImageNew";
import SEO from "../components/SEO";
import Title from "../components/Title";
import Container from "../components/Container";
import Note from "../components/Note";
import Card from "../components/Card";
import Cover from "../components/Cover";

// TODO: Add Transitions
// https://transitionlink.tylerbarnes.ca/docs/transitionlink/

const IndexPage = () => (
  <Main>
    <Note />

    <StaticQuery
      query={graphql`
        query KanzleiQuery {
          allMarkdownRemark(
            filter: { frontmatter: { name: { eq: "kanzlei" } } }
          ) {
            edges {
              node {
                frontmatter {
                  vorname
                  beschreibung
                  bild
                  kurzform
                  link_name
                  link_url
                  name
                  rechtsanwalte
                  text
                  titel
                  title
                  uberschrift
                  unteruberschrift
                }
                rawMarkdownBody
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
          {data.allMarkdownRemark.edges.map((e) => (
            <div>
              <SEO title="Home" />
              <Container>
                <Title
                  titleName={e.node.frontmatter.uberschrift}
                  titleSub={e.node.frontmatter.unteruberschrift}
                  titleLink={e.node.frontmatter.link_name}
                  titleLinkURL={e.node.frontmatter.link_url}
                />
              </Container>
              <Cover>
                {/* TODO: Change component to ImageNew */}
                {/* <ImageNew filename={e.node.frontmatter.name + ".jpg"} /> */}
                <Image />
                {/* {e.node.frontmatter.name} */}
              </Cover>
              <Container>
                <Styled.p sx={{ variant: "text.bigLetter" }}>
                  {/* TODO: Show images from /upload folder in markdown */}
                  <ReactMarkdown sx={{ variant: "text.markdown" }}>
                    {e.node.rawMarkdownBody}
                  </ReactMarkdown>
                </Styled.p>
              </Container>
            </div>
          ))}
        </div>
      )}
    />

    <Container>
      <Card
        cardTitle="Rechtsanwalt finden"
        cardImage="none"
        cardLink=""
        cardLinkURL="/"
      />

      <Card
        cardTitle="Rechtsgebiete entdecken"
        cardImage="none"
        cardLink=""
        cardLinkURL="/"
      />

      <Card
        cardTitle="Zum ersten Mal beim Rechtsanwalt?"
        cardImage="none"
        cardLink="Zu den häufigsten Fragen"
        cardLinkURL="/"
      />
    </Container>
  </Main>
);

export default IndexPage;
