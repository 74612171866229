/** @jsx jsx */
import { Styled, jsx } from "theme-ui";
import { StaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import Container from "../components/Container";

export default () => (
  <StaticQuery
    query={graphql`
      query NoteQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/hinweis/" }
            frontmatter: { aktivitat: { eq: true } }
          }
        ) {
          edges {
            node {
              frontmatter {
                aktivitat
                text
                markierung
                uberschrift
              }
              parent {
                ... on File {
                  name
                  relativeDirectory
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
        {data.allMarkdownRemark.edges.map((document) => (
          <Container
            sx={{
              mt: [2, 3],
              pt: [2, 3],
              pb: [2, 3],
              backgroundColor: "highlight",
              textAlign: "center",
              variant: "shadow.default"
            }}
          >
            <div
              sx={{
                fontSize: [0, 1],
                padding: "3px 10px 2px",
                borderRadius: "32px",
                display: "inline-block",
                mx: [1, 3],
                fontWeight: 700,
                backgroundColor: "background",
              }}
            >
              {/* Aktuller Hinweis */}
              {document.node.frontmatter.markierung}
            </div>
            <Styled.h4 sx={{ px: [1, 3], mt: 2 }}>
              {/* <span role="img" aria-label="covid-19">🦠 </span> Kontakt während der COVID-19 Pandemie */}
              {document.node.frontmatter.uberschrift}
            </Styled.h4>
            <div sx={{ px: [1, 3] }}>
              {/* Damit wir Sie und unser Team vor den Risiken einer Ansteckung mit
              dem Corona-Virus bestmöglich schützen können, empfangen wir
              Mandanten <b>nur nach vorheriger telefonischer Vereinbarung</b>.{" "}
              <i>Bleiben Sie gesund.</i> */}
              <ReactMarkdown>{document.node.frontmatter.text}</ReactMarkdown>
            </div>
          </Container>
        ))}
      </div>
    )}
  />

  // {document.node.frontmatter.name}
);
