/** @jsx jsx */
// import React from "react"
import { Styled, jsx } from "theme-ui";
// import { Link } from "gatsby"
// import PropTypes from "prop-types"

// import Link from "../components/Link"

export default ({ cardTitle, cardImage, cardLink, cardLinkURL }) => (
  <a
    href={cardLinkURL}
    sx={{
      textDecoration: "none",
      // display: 'cell',
      // width: ['100%', '33%']
    }}
  >
    <div
      sx={{
        px: [2, 3],
        py: [2, 2],
        mx: ["0%", "2%"],
        mb: ["2%"],
        border: '1px solid',
        borderColor: "muted",
        // backgroundColor: "highlight",
      }}
    >
      <Styled.h2 sx={{ color: "text", margin: 0 }}>{cardTitle}</Styled.h2>

      <Styled.div sx={{ color: "primary" }}>{cardLink}→</Styled.div>
    </div>
  </a>
);
